export const initReviewsCaro = () => {
	const caroSettings = {
		gutter: 34,
		animationDuration: 1500,
		currentSlide: 0,
		currentPos: 0,
		breakpoints: {
			sm: 320,
			md: 800,
			lg: 1150,
		},
		slideCount: {
			lg: 3,
			md: 2,
			sm: 1
		},
		gutters: {
			lg: 34,
			md: 20,
			sm: 20
		}
	};
	
	const _getCurrentBreakpoint = () => {
		let largest = 'sm';
		for(const [k, p] of Object.entries(caroSettings.breakpoints)) {
			if(window.innerWidth > p) {
				largest = k;
			}
		}
		return largest;
	};

	const _getCurrentGutter = () => {
		return caroSettings.gutters[_getCurrentBreakpoint()];
	};

	const cards = document.querySelectorAll('.review_card');
	const caroLeft = document.querySelector('#caro_left');
	const caroRight = document.querySelector('#caro_right');
	
	
	const updateSlidePositions = () => {
	
		let slideNum = -caroSettings.currentPos;

	
		cards.forEach(i => {
			const width = i.getBoundingClientRect().width;
			i.style.left = `${(width * slideNum) + ( _getCurrentGutter()/2 ) + (_getCurrentGutter() * slideNum)}px`;
			slideNum++;
		});
	
		document.querySelector('#inc_reviews_caro').removeAttribute('unresolved');
	};
	
	const _slideCaro = startAt => {
		let start;
		let atEnd = false;
		const initialVal = caroSettings.currentSlide;
		if(startAt <= 0) {
			startAt = 0;
		} else {
			caroLeft.removeAttribute('hide');
		}

		if ((cards.length) - startAt <= caroSettings.slideCount[_getCurrentBreakpoint()]) {
			startAt = cards.length - caroSettings.slideCount[_getCurrentBreakpoint()];
			atEnd = true;
		} else {
			caroRight.removeAttribute('hide');
		}

		caroSettings.currentSlide = startAt;
		
		const animateSlides = (ts) => {
			start = start || ts;
			let progress = (ts - start)/caroSettings.animationDuration;
			progress = (1 - Math.pow(1 - progress, 3));
			progress = Math.min(1, progress);
			caroSettings.currentPos = initialVal + (startAt - initialVal) * progress;
			if(progress < 1) {
				requestAnimationFrame(animateSlides);
			} else {
				if(startAt === 0) {
					caroLeft.setAttribute('hide', true);
				}
				if(atEnd) {					
					caroRight.setAttribute('hide', true);
				}
			}
			updateSlidePositions();
		};
		requestAnimationFrame(animateSlides);
	};

	const _slideReviewsLeft = () => {
		_slideCaro(caroSettings.currentSlide - caroSettings.slideCount[_getCurrentBreakpoint()]);
	};

	const _slideReviewsRight = () => {
		_slideCaro(caroSettings.currentSlide + caroSettings.slideCount[_getCurrentBreakpoint()]);
	};

	window.addEventListener('resize', () => {
		_slideCaro(caroSettings.currentSlide);
		updateSlidePositions();
	});

	
	caroLeft.addEventListener('click', _slideReviewsLeft);
	caroRight.addEventListener('click', _slideReviewsRight);

	document.querySelector('#inc_reviews_caro').addEventListener('click', e => {
		const clickedButton = e.target.closest('button');

		if(clickedButton.classList.contains('close_overlay')) {
			clickedButton.closest('.card_full_text').removeAttribute('show');
		} else {
			if(clickedButton) {
				const theCard = e.target.closest('.review_card');
				theCard.querySelector('.card_full_text').setAttribute('show', true);
			}
		}



	});

	// const theOverlay = document.querySelector('#review_overlay');

	// const overlayStars = document.querySelector('#overlay_stars');
	// const overlayName = document.querySelector('#overlay_name');
	// const overlayText = document.querySelector('#overlay_text');

	// document.querySelector('#reviews_caro_outer').addEventListener('click', e => {
	// 	const clickedButton = e.target.closest('.review_read_more button');

	// 	if(clickedButton) {
	// 		const theCard = e.target.closest('.review_card');

	// 		theOverlay.setAttribute('show', true);

	// 		overlayName.innerHTML = theCard.getAttribute('name');
	// 		overlayText.innerHTML = theCard.getAttribute('text');
	// 		overlayStars.innerHTML = '';

	// 		for(let i = 0; i < 5; i++) {
	// 			const starImg = document.createElement('img');
	// 			starImg.src = `/static/images/icons/${(i < theCard.getAttribute('rating'))?'star_full':'star_empty'}.svg`;
	// 			overlayStars.append(starImg);

	// 		}
	// 	}

	// 	if(e.target.closest('#overlay_close')) {
	// 		theOverlay.removeAttribute('show');
	// 	}
	// });
	
	updateSlidePositions();
};