import './polyfills';

import { initMobileNav } from './header';
import { initFacilityMobile, facilityCaro, initFacFilters, facilityMap } from './facility'; 
import { initReviewsCaro } from './reviewsCaro';
import { contactSubmit } from './contact.js';
import { multiMap } from './multiMap';

initMobileNav();

if(document.querySelector('#facility_top')) {
	initFacilityMobile();
}

if(document.querySelector('#facility_caro')) {
	facilityCaro();
}

if(document.querySelector('#facility_filters')) {
	initFacFilters();
}

if(document.querySelector('#facility_map')) {
	facilityMap();
}

if(document.querySelector('#inc_reviews_caro')) {
	initReviewsCaro();
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'facility', 'message']
	});
}

// Multi Map
if(document.querySelector('#multi_map')) {
	multiMap();
}

// Multi Map
if(document.querySelector('#multi_map')) {
	multiMap();
}

//Accordion styling for Tips
if (document.getElementById('tips_items_wrap')) {
	setTimeout(function(){
		document.getElementsByClassName('tips_item_wrap')[0].classList.add('active');
	}, 500);
	const helpItems = document.querySelectorAll('.tips_item_wrap');
	document.getElementById('tips_items_wrap').addEventListener('click', function(e) {
		if(e.target && e.target.nodeName === "H2") {
			let el = e.target.parentElement;
			if(el.classList.contains('active')) {
				el.classList.remove('active');
			}
			else {
				helpItems.forEach(i => {
					i.classList.remove('active');
				});
				el.classList.add('active');
			}
		}
	});
}
